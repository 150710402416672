/*! {!project_name!} v0.1.0 */
/**
 * CSS Stylesheet for: {!project_name!} v0.1.0
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 * CSS Custom properties
 * Plugins style's override
 * Base
 * Layout
 * * Header
 * * Sections
 * * Footer
 * Media queries
 * 
 * @todo Add each module/section needed
 * 
 */


.check-notification.slide:checked+.notification.slide {
  top: -50vw;
}

body{
  /*background-color: #0b0f10;*/
    background-image: url('../img/fondo.jpg');


}



/* Fontawesome rebase font file urls */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/fontawesome/fa-brands-400.eot");
  src: url("../fonts/fontawesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-brands-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-brands-400.woff") format("woff"), url("../fonts/fontawesome/fa-brands-400.ttf") format("truetype"), url("../fonts/fontawesome/fa-brands-400.svg#fontawesome") format("svg"); }
.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/fontawesome/fa-regular-400.eot");
  src: url("../fonts/fontawesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-regular-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-regular-400.woff") format("woff"), url("../fonts/fontawesome/fa-regular-400.ttf") format("truetype"), url("../fonts/fontawesome/fa-regular-400.svg#fontawesome") format("svg"); }
.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fontawesome/fa-solid-900.eot");
  src: url("../fonts/fontawesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-solid-900.woff2") format("woff2"), url("../fonts/fontawesome/fa-solid-900.woff") format("woff"), url("../fonts/fontawesome/fa-solid-900.ttf") format("truetype"), url("../fonts/fontawesome/fa-solid-900.svg#fontawesome") format("svg"); }
.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

/* CSS Custom Properties */
@import '_project_variables.css';

p{
  text-align: justify;
}
p,ul{
  line-height: 1.8;
  
}
ul{
  margin-left: 80px;
}
.titulos-practicas-card p{
  max-width: 250px;
}
/* Plugins style's override */


/* arriba*/
.go-up{
  z-index: 1;
}
#link-go-up.visible {
    bottom: 40px;
}

#link-go-up:link, #link-go-up:visited {
    position: fixed;
    bottom: 90px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #dd9928;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    transition: all .4s ease 0s;
}
::placeholder {
  font-style: italic;
}

.container.boxed{
  /*width: 1440px;*/
  max-width:1340px; /*1380px;*/
}
/* Base */
.test-class {
  color: var(--complementary-color-1);
}
.new-class {
  color: black;
}
/* firmas */

#firmas h2{
  text-align: center;
}
#firmas{
  text-align: justify;
}
.bienvenida-contacto #form-contact #button-submit{
  margin-left: -400px;
}

.politica-calidad-formulario-contenedor-izquierda p{
  text-align: left !important;
}
/* alianzas */

.alianza-servicios-coordinados h4{
  text-align: center;
}
.alianza-servicios-coordinados{
  text-align: justify;
}
#alianza h3{
  text-align: center;
}
#alianza {
  text-align: justify;
}
/*formulario contacto */

fieldset{
  padding: 0px; 
  border: none;
}

/* mapa */

.map { 
     -webkit-filter: grayscale(100%); 
     -moz-filter: grayscale(100%); 
     -ms-filter: grayscale(100%); 
     -o-filter: grayscale(100%); 
     filter: grayscale(100%); 
} 

/* Layout */
.logo-bae{
  max-width: 100%;
}
.heading{
  text-align: center;
}
footer p {
    font-size: 15px;
    letter-spacing: 0.75px;
    line-height: 1.74;
    color: #8f8f8f;
    padding-top: 30px;
    max-width: 747px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}
.direccion-footer{
text-align: left;
padding-left: 45px !important;
 color: #8f8f8f;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  letter-spacing:0.75px;
  line-height: 1.74;
  margin-top: -15px !important;
}
.direccion-footer-email{
  margin-top: -4px !important;
}
.direccion-footer-titulo{
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 15px;
  letter-spacing:0.75px;
  line-height: 1.74;
  padding-left: 45px;
}
.titulo-privacidad{
      color: var(--text-color);
    text-align: center;
}
.header-privacidad{
  background-color: rgba(0, 0, 0, 1);
}

.espacio-secciones-privacidad{
  height: 200px;
}

.espacio-secciones{
  height: 36px;
}

.espacio-secciones2{
  height: 62px;
}

.espacio-secciones3{
  height: 100px;
}

.espacio-secciones4{
  height: 77px;
}

/* * Header */
.nav-eng-es a:link, .direccion-footer a:link {
    /*color: #515151 !important;*/
    color: #c1c1c1;
    
}

.nav-eng-es a:visited, .direccion-footer a:visited {
    /*color: #515151 !important;*/
    color: #c1c1c1;
}

.collapsed .nav-eng-es a:visited, .direccion-footer a:visited {
color: #515151 !important;
  }
  .collapsed .nav-eng-es a:link, .direccion-footer a:link {
color: #515151 !important;
  }
.link-linkedin{
  margin-left: 40px;
}
.espacio-linkedin-footer{
  margin-right: 20px;
}

a.main.active {
    color: #bebebe;
    color: var(--complementary-color-2-darker);
    border-bottom: 3px solid #fff;
    padding-bottom: 48px;
}
header.collapsed {
  background-color: rgba(0, 0, 0, 1);
  height: 160px;
}
header.collapsed .header-logo-wrapper{
  text-align: left !important;
  display: inline-block;
}
header.collapsed nav{
  display: inline-block;
      margin-left: 15%;
}
header.collapsed nav {
  line-height: 60px;
}
header.collapsed .header-logo-wrapper {
  margin: 10px 0;
}
header.collapsed a.main:active, header.collapsed a.main:hover, 
header.collapsed a.main.active {
  padding-bottom: 18px;
}
header.collapsed .navigation label {
  margin-top: 16px;
  transition: all .4s ease-out;
}
header {
    position: fixed;
    z-index: 2;
    width: 100%;
    /*height: 120px;*/
    height: 70px;
    
    background-color: rgba(255, 255, 255, 0.01);
    
}
.header-logo-wrapper {
    margin-left: 0;
    margin-right: 0;
    transition: all .4s ease-out;
margin-top: 50px;
margin-bottom: -5px;

}
section {
	margin-top: calc(2 * var(--rhytm-unit));
	margin-bottom: calc(2 * var(--rhytm-unit));
}
/* * Sections */
/* parallax*/
.simpleParallax{
  position: absolute;
}
/* Slider */
.video-contenedor{
  text-align: center;
}

.swiper-slide{
  /*background-image: url("../img/slider-02.jpg");

    background-position: center;
    background-repeat: no-repeat;*/
    height: 100%;
    width: 100%;
        /*position: relative;*/
        /*color: #ffffff;*/
}
.swiper-button-next, 
.swiper-button-prev {
    position: absolute;
    top: 60%;
    width: 44px;
    height: 86px;
    margin-top: -43px;
    z-index: 10;
    cursor: pointer;
    background-size: cover;
    transition: all .4s ease;
}
.swiper-button-next {
    background-image: url('../img/chevron-left-right-sprite.png');
    background-position: left center;
    right: 80px;
    left: auto;
}
.swiper-button-prev {
  background-image: url('../img/chevron-left-right-sprite.png');
  background-position: right center;
  left: 80px;
  right: auto;
}
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, .3);
  border: 2px solid #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active:after {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 100%;
    margin: 2px auto;
}

.testimonials .swiper-slide{

    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
    width: 100%;
        position: relative;
        color: #ffffff;
}


.slider-calidad-equipo-titulo{
  font-size: 30px;
  font-family: "Roboto Slab";
  letter-spacing:1.5px;
  line-height: 0.87;
  color: #ffffff;
  font-weight: 300;
  padding-bottom: 30px;
}
#slider-calidad-equipo p{
  padding-top: 0px;
  font-size: 15px;
  letter-spacing:0.75px;
  line-height: 1.74;
  color: #8f8f8f;
  font-family: "Roboto";
  font-weight: 300;
  padding-bottom: 30px;
}
#slider-calidad-equipo{
  margin-bottom: 100px;
}
.calidad-equipo-nosotros .swiper-slide{

    background-position: center;
    background-repeat: no-repeat;
    height: 440px;
    width: 100%;
        position: relative;
        color: #ffffff;
}
.swiper-container {
    width: 100%;
    height: 100%;
    }

.altura-slider{
  height: 100%;
}
.swiper-slide .h2{
/*color: #ffffff;*/
}

/* politica calidad formulario */

.estilo-especial-politica-calidad-home{
      padding-top: 50px;
      padding-left: 70px;
     
}
.politica-calidad-formulario-contenedor-izquierda{
   background-color: #0e161a;
   max-width: 650px;
}

.politica-calidad-formulario-titulo{
  font-size: 25px;
  font-family: "Roboto Slab";
  font-weight: 300;
  color: #ffffff;
  letter-spacing:1.25px;
line-height: 1.04;
}

.bienvenida-hierro-ductil-hr{
  width: 100px;
    height: 1px;
    color: #dd9928;
    background-color: #dd9928 !important;
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 18px;
}

.bienvenida-hierro-gris-hr{
  width: 100px;
    height: 1px;
    color: #dd9928;
    background-color: #dd9928 !important;
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 18px;
}
.contenedor-certificado-membresia{
  height: 250px;
}
.contenedor-certificado-membresia2{
  height: 250px;
}
.contenedor-boton-cerrar-certificado{
  text-align: center;
}
.certificados{
  text-align: center;
  background-color: #0e161a;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -350px;

}

.membresia{
  text-align: center;
  background-color: #0e161a;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -350px;

}
.bienvenida-nosotros #practicas{
  height: auto;
}
.heading-bienvenida-nosotros{
  text-align: left !important;
}
.bienvenida-nosotros-hr{
  width: 100px;
    height: 1px;
    color: #dd9928;
    background-color: #dd9928 !important;
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 18px;
}
.politica-calidad-formulario-imagen{
  padding-bottom: 70px !important;
}
#politica-calidad-formulario p{
font-family: "Roboto";
font-weight: 300;
font-size: 15px;
color: #8f8f8f;
letter-spacing:0.75px;
line-height: 1.74;
max-width: 513px;
margin: 0px;
padding: 0px;
padding-bottom: 30px;
}
#politica-calidad-formulario{
  margin-bottom: 43px;
}

/* Swiper slider video*/
.texto-video{
 /* text-align: center;
    height: 100%;
    padding-top: 25%;*/
    position: absolute;
    top: 60%;
    width: 100%;
    /* height: 86px; */
    margin-top: -43px;
    font-family: "Roboto Slab";
    font-weight: 300 !important;
    font-size: 52px;
    letter-spacing:1.04px;
    line-height: 0.5;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 140px;
  top: 770px;
}
.bienvenida-home{
      margin-top: -197px;
    z-index: 1;
    margin-bottom: 0px;
}
.bienvenida-nosotros{
  margin-top: -70px;
}

.bienvenida-hierro-ductil{
  margin-top: -71px;
  margin-bottom: 51px;
}
.bienvenida-hierro-gris{
  margin-top: -71px;
  margin-bottom: 51px;
}
.bienvenida-capacidad-equipo{
  margin-top: -97px;

}

.bienvenida-calidad{
  margin-top: -70px;
 margin-bottom: -70px;
}

.bienvenida-contacto{
  margin-top: -110px;/*-397px;*/
}
.bienvenida-home .heading{
  color: #ffffff;
  font-size: 35px;
  letter-spacing:1.75px;
  line-height: 0.75;
  padding-bottom: 30px;
}
.bienvenida-hr{
  width: 100px;
  height: 1px;
  color: #dd9928;
  background-color: #dd9928 !important;
  margin: auto;
  margin-top: -9px;
    margin-bottom: -6px;
}
.calidad-equipo-slider-hr{
  width: 100px;
  height: 1px;
  color: #dd9928;
  background-color: #dd9928 !important;
  margin: auto;
  margin-top: -12px;
    margin-bottom: -18px;
}
.bienvenida-home p{
  font-size: 15px;
  letter-spacing:0.75px;
  line-height: 1.74;
  color: #8f8f8f;
  padding-top: 30px;
  max-width: 747px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.seccion-mapa-contacto{
  margin-bottom: 100px;
}
.seccion-hierro-gris-formulario{
  margin-bottom: 100px;
}
.swiper-pleca-video{
      /*background-image: url('../img/pleca-video.png');*/
          position: absolute;
    top: 0;
    width: 100%;
    height: 99.5%;
    background-repeat: repeat-x;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.1);
}

.swiper-logo-video{
      /*background-image: url('../img/pleca-video.png');*/
          position: absolute;
    bottom: 120px;
    right: 220px;
    z-index: 9;
    
}

/* * Nav */
header nav {
    line-height: 120px;
}
.menu a{
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing:0.7px;
  line-height: 1.36;
  color: #cacaca;
}

a.main:hover {
    color: #cacaca;;
    color: var(--complementary-color-2-darker);
    border-bottom: 3px solid #dd9928;
    padding-bottom: 10px;
}
a.main:visited {
    text-transform: uppercase;
    color: #cacaca;;
    margin-left: 20px;
    margin-left: 43px;/*var(--rhytm-unit);*/
    /*font-size: .75rem;*/
    transition: all .4s ease-out;
    border-bottom-width: 3px;
}
a.main:link {
    text-transform: uppercase;
    color: #cacaca;;
    margin-left: 20px;
    margin-left: 43px;/*var(--rhytm-unit);*/
   /* font-size: .75rem;*/
    transition: all .4s ease-out;
    border-bottom-width: 3px;
}

.banner-facilitador-soluciones {
    color: #fff;
    background: transparent url(../img/slider-01.jpg) center no-repeat;
    background-size: cover;
    padding-top: calc(7 * 20px);
    padding-top: calc(7 * var(--rhytm-unit));
    padding-bottom: calc(7 * 20px);
    padding-bottom: calc(7 * var(--rhytm-unit));
    height: 959px;
}

.heading{
  color: #000000;
  font-weight: 300;
}
.white{
  color: #ffffff;

}

.image-grid-especial{
  width: 100%;
}
.ocultar-contenedor-practica{
  display: none !important;
}
.link-ocultar{
  width: 100% ;
}
.practicas-ocultas a{
  text-decoration: none;
}

.practicas-ocultas a{
  border-bottom: none;
}
.practicas .grid {
  flex-wrap: wrap;
}
.practicas .card {
  position: relative;
  color: #fff;
      margin-bottom: -1px;
          margin-top: 27px;

}
.practicas .card .heading {
  text-transform: uppercase;
  line-height: normal;
}
.card-imagenes-ductil-gris{
  vertical-align: top;
    margin-top: 140px;
    margin-left: 46px;    
}
.titulos-practicas-card{
  height: 100%;
}
.practicas{
  height: 483px;
  margin-bottom: 100px;
}
.practicas .card:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.practicas p{
  padding-top: 10px;
}
.practicas .card .image {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: -1;
  top: 0;
}
.practicas .card p{
  max-width: 255px;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 300;
  margin-left: 60px;
  letter-spacing:0.75px;
  line-height: 1.74;
  color: #8f8f8f;

}
.card-ductil-gris-hr{
  width: 100px;
  height: 1px;
  color: #dd9928;
  background-color: #dd9928 !important;
  margin-left: 70px;
  margin-top: 26px;
    margin-bottom: 13px;
}

.politica-calidad-formulario-hr{
  width: 100px;
  height: 1px;
  color: #dd9928;
  background-color: #dd9928 !important;
  margin-left: 0px;
  margin-top: 26px;
    margin-bottom: 23px;
}

.capacidad-equipo-hr {
    width: 100px;
    height: 1px;
    color: #dd9928;
    background-color: #dd9928 !important;
    margin-left: 0px;
   margin-top: -12px;
    margin-bottom: 23px;
}

.heading-hierro-ductil-gris-card{
  font-size: 25px;
  font-weight: 300;
  letter-spacing:1.25px;
  line-height: 1.04;
  font-family: "Roboto Slab";
  color: #ffffff;
  padding-left: 70px;
  padding-top: 50px;
}

.margin-hierro-ductil-gris-left-home p, .margin-hierro-ductil-gris-right-home p{
  text-align: left;
}

a.card:link, a.card:visited {
  outline: 1px solid rgba(255, 255, 255, 0);
  outline-offset: -var(--rhytm-unit);
  border: 0;
}
/*a.card:hover, a.card:active {
  background-color: rgba(0, 0, 0, .75);
  outline-color: #fff;
}*/
/*a.card:hover .content, a.card:active .content {
  /*padding: calc(2 * var(--rhytm-unit));
  display: block;
  position: absolute;
}*/
.estilos-practicas ul{
      margin-top: calc(.5 * var(--rhytm-unit));
}
.practicas_content{
  /*padding-top: 40px;
  padding-bottom: 80px;*/
   background-color: #f3f3f3;
}
.estilos-practicas{
  background-color: #f3f3f3;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right:80px;
}
.practicas-ocultas{
  display: flex !important;
}
.practicas-ocultas h4{
color: #a4664d;
margin-left: calc(.5 * 40px );
font-weight: 400;
}
.practicas-ocultas p{
margin-left: calc(.5 * 40px );
}
.nav-eng-es{
display: inline;
margin-left: calc(2 * var(--rhytm-unit));
}
.nav-eng-es img{
  vertical-align: middle;
}
.testimonials p{
  color: #ffffff;
  font-weight: 400;
  /*text-align: justify;*/
}

.calidad-equipo-nosotros p{
  color: #ffffff;
  font-weight: 400;
  /*text-align: justify;*/
}

/* * Footer */
footer {
	min-height: 267px;
	/*background: var(--complementary-color-1);*/
  background-color: #000000;
  padding-top: 50px;
}
footer .copyright{
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 300;
  color: #8f8f8f;
  letter-spacing:0.75px;
  line-height: 1.74;
  padding-top: 33px;
}
.footer-linkedin{
  vertical-align: top;
}

a.altavoz {   
  width: 19px;
  height: 19px;
  display: block;
  background: transparent url('../img/logo-altavoz.png');
  background-position: left center;
  background-repeat: no-repeat;
  bottom: 15px;
    position: absolute;
}
a.altavoz:hover, a.altavoz:active {
    width: 70px;
}
.h2{
      font-weight: 100;

}
.ciudad-cabecera{
  color:#a4664d;
  font-family: "Lato";
}

#addresses ul{
      list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    line-height: 35px;
}
#addresses .column .content {
    padding-left: calc(2 * 20px);
    padding-left: calc(2 * var(--rhytm-unit));
}

video {
  /*margin-left: calc((100vw - (100vh * 1.7))/2);
  margin-right: calc((100vw - (100vh * 1.7))/2);*/
  min-height: auto;
  max-width: 100%;
  width: 100%;
}

.linkedin-footer-p{
  text-align: right;
  float: right;
}
/* Media queries */
@media (min-aspect-ratio: 16/9) {
  video {
    margin-left: 0;
  }
}
@media (max-width: 1380px) and (min-width: 1141px) {
  .container.boxed {
   /* padding-left: 5%;
    padding-right: 5%;*/
     padding-left: calc(2 * var(--rhytm-unit));
   /* padding-right: calc(2 * 20px);*/
    padding-right: calc(2 * var(--rhytm-unit));
  }
}
@media (max-width: 1280px) {}
@media (max-width: 1140px) {
  .container.boxed {
    /*padding-left: calc(2 * 20px);*/
    padding-left: calc(2 * var(--rhytm-unit));
   /* padding-right: calc(2 * 20px);*/
    padding-right: calc(2 * var(--rhytm-unit));
}
}
@media(min-width: 1025px){
  .capacidad-equipo-article{
    margin-bottom: 50px !important;
  }
  .bienvenida-capacidad-equipo p{
    font-size: 15px !important;
  }
  .swiper-button-prev,.swiper-button-next{
   -webkit-transform: rotate(-180deg);
  
 -moz-transform: rotate(-180deg);
 -ms-transform: rotate(-180deg);
 transform: rotate(-180deg);
}
}
@media (max-width: 1024px) {

/*calidad y equipo */

#capacidad-moldeo{
    content:url('../img/calidad-equipo/capacidad_moldeo_mov.jpg');
}

#capacidad-corazones{
    content:url('../img/calidad-equipo/capacidad_corazones_mov.jpg');
}

#capacidad-fusion{
    content:url('../img/calidad-equipo/capacidad_fusion_mov.jpg');
}
#capacidad-inspeccion{
    content:url('../img/calidad-equipo/capacidad_inspeccion_mov.jpg');
}

#capacidad-termico{
    content:url('../img/calidad-equipo/capacidad_termico_mov.jpg');
}

a.main:link{
  margin-left: 24px;
}
  .swiper-logo-video{
  display: none;
  }
  ul{
  margin-left: 0px;
}

}
@media (max-width: 980px) {}
@media (max-width: 780px) {

  .capacidad-equipo-article .alianza-container{
    margin-top: 0px;
    padding-top: 0px;
  }
  .capacidad-equipo-article p{
   /* width: 100% !important;*/
   width: 80% !important;
    text-align: center;
    margin: auto !important;
  }
  .capacidad-equipo-article .calidad-equipo-filas-izquierda{
    margin-left: 0px;
    margin-top: 0px;
  }
  .capacidad-equipo-article .titulos-seccion-calidad-equipo{
    text-align: center;
  }
  .capacidad-equipo-article .capacidad-equipo-hr{
    text-align: center;
    margin: auto;
    margin-bottom: 5px !important;
    margin-top: -23px;
  }

  .margin-politica-calidad-contacto-home-left{
    padding-right: 0px !important;
  }
  .slider-calidad-equipo-titulo, .politica-calidad-formulario-titulo{
        line-height: 1.5;
  }
  .linkedin-footer-p {
    margin-top: 15px !important;
  }
  footer{
    height: 500px;
  }
  .bienvenida-contacto #form-contact #button-submit{
    margin-left: 0px !important;
  }
  .calidad-imagen-final{
    margin-top: 90px;
  }
  .heading-bienvenida-nosotros{
    text-align: center !important;
  }
  .bienvenida-hierro-gris #slider-calidad-equipo{
    margin-top: -150px;
  }
  .bienvenida-hierro-gris-hr{
    margin-left: auto;
    margin-right: auto;
  }
  .bienvenida-hierro-ductil #slider-calidad-equipo{
    margin-top: -360px;
  }
  .bienvenida-hierro-ductil-hr{
    margin-left: auto;
    margin-right: auto;
  }
  #form-contact #button-submit{
   margin-left: 14%;
    /* margin-top: -40px; */
    top: -135px;
  }
  #recaptcha{
    margin-left: 12% !important;
    margin-top:100px;
  }
  .practicas .card {
        padding-bottom: 336px;
  }
  .card-imagenes-ductil-gris{
    margin-left: 0px;
  }
  .one-half.column, .one-third.column, .one-quarter.column, .two-thirds.column, .three-quarters.column{
    width: 100% !important;/*96px; */
  }
  .bienvenida-nosotros .heading-bienvenida-nosotros{
    line-height: 1.5;
    text-align: center !important;
        max-width: 94%;
  }
  .bienvenida-nosotros-hr{
    margin-left: auto;
    margin-right: auto;
  }
  .heading-hierro-ductil-gris-card{
    padding-left: 0px;
    text-align: center;
  }
  .card-ductil-gris-hr{
    margin-left: auto;
    margin-right: auto;
  }
.bienvenida-hierro-gris .margin-hierro-ductil-gris-right-home .imagen-seccion-gris{
margin-top: 110px !important;
}
.bienvenida-hierro-ductil .margin-hierro-ductil-gris-right-home img{
      margin-top: 365px !important;
    }
  #bienvenida-nosotros p{
    text-align: center;
        max-width: 94% !important;
  }
  .bienvenida-home .heading{
    line-height: 1.5;
  }

  .hierro-gris-especial{
    height: 100px;
  }
  #bienvenida-nosotros .margin-hierro-ductil-gris-right-home img{
    display: block;
   /*margin-top: 365px;*/
   margin-top: 250px;

  }
.bienvenida-nosotros #slider-calidad-equipo{
  margin-top: 140px;
} 
  .bienvenida-calidad .margin-hierro-ductil-gris-right-home img{
    display: block;
    margin-top: 100px !important;
  }
   .bienvenida-calidad{
    height: 796px;
    margin-bottom: 100px;
   }
  #bienvenida-nosotros .margin-hierro-ductil-gris-left-home{
    background-color: transparent;
  }

  #bienvenida-nosotros .margin-hierro-ductil-gris-right-home{
    background-color: transparent;
  }
.bienvenida-contacto form{
  margin-top: 250px;
}

  .swiper-container{
    margin-top: 170px;/* 170px;*/
  }
  .testimonials .swiper-container{
    margin-top: 670px;
  }

  .bienvenida-nosotros .testimonials .swiper-container, .bienvenida-hierro-gris .testimonials .swiper-container{
margin-top: 170px;
  }
.bienvenida-nosotros{
  margin-top: 50px;
}
.bienvenida-home{
  margin-top: 50px;
}
#slider-calidad-equipo .swiper-container img{
  width: 1700px;
  height: auto;
}
  .swiper-container img{
    width: 100%;
    /*width: 1700px;*/
    height: auto;
  }
  .direccion-footer-titulo, .direccion-footer{
    padding-left: 0px;
  }
  footer p, footer h4{
    text-align: center !important;
  }
  .footer-especial{
    padding-left: 9rem;
    width: 100%;
  }
  .linkedin-footer-p{
    position: absolute;
    text-align: center;
        margin-top: 50px;
  }
  .swiper-button-prev{
    background-size: 49px;
    background-position-x: 5px;
  }
  .swiper-button-next{
        background-size: 49px;
    background-position-x: -24px;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 30px !important;
}
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 20px;
  }
  h2{
    font-size: 2rem;
  }
  #practicas{
    margin-top: -80px;
  }
  #addresses{
    margin-top: -300px
  }
  #alianza{
    height: 1400px;
  }
  .alianza-container{
        margin-top: 30px;
            padding-bottom: 250px;
    padding-top: 300px;
  }
  #slider-calidad-equipo{
    margin-bottom: 21px;
  }
  .alianza-container-bae{
    margin-top: -270px;
  }
  .contacto-bae{
    margin-top: -110px;
  }
  .logo{
    margin-top: 80px;
    
  }
  .ir{
    color: transparent !important;
    text-indent: 0;
  }
  .practicas_content_movil{
    max-width: 100%;
  }
  #firmas{
    margin-top: -80px;
  }
  .espacio-secciones3{
    display: none;
  }
  .estilo-especial-politica-calidad-home, .copyright, .footer-especial{
    text-align: center;
  }
.container.boxed, .swiper-slide .container {
    padding-left: 20px;
    padding-right: 20px;
   /* padding-bottom: 20px;*/
}
#alianza .swiper-slide .container{
 padding-bottom: 200px; 
 margin-top: 40px;
}

  .titulos-practicas-card{
        position: absolute;
  }
  .navigation.slide-l .menu{
    background-color: rgba(0, 0, 0, .75);
  }
  .collapsed .header-logo{
    width: 20%;
        margin-left: 0px;
        margin-top: 30px;
  }
  nav.menu a {
    display: block;
  }

  .nav-eng-es img{
  vertical-align: middle;
  float: left;
}



  a.main:link {
    display: block;
    text-align: left;
    margin-bottom: 20px;
    margin-bottom: var(--rhytm-unit);
    margin-left: 0;
}

article {
    padding: calc(2 * var(--rhytm-unit)) 0;
  }
  .header-logo-wrapper {
    float: none;
    width: 100%; /*70%;*/
    text-align: -webkit-center;
  }
  header.collapsed .header-logo-wrapper{
        text-align: center !important;
  }
  .label-menu-toggle {
    margin-top: -80px !important;
    float: left;
  }
  .nav-eng-es{
    float: left;
    margin-left: 0px;
  }
  .nav-eng-es span{
    display: none;
  }
  .navigation.slide-l .menu {
    background-color: rgba(0, 0, 0, .75);
    height: calc(100vh - 120px);
    margin-top: 140px;
    width: 100%;
    left: -165vw;/* left: -100vw; */
    padding-right: 40px;

  }
  header.collapsed nav{
        margin-left: 0%;
  }
  a.main:link, a.main:active {
    display: block;
    text-align: left;
    margin-bottom: var(--rhytm-unit);
    margin-left: 0;
  }
  a.main:active, a.main:hover, 
  a.main.active {
    padding-bottom: 0;
  }
  header.collapsed .navigation.slide-l .menu {
    height: calc(100vh - 60px);
    margin-top: 60px;
  }
  header.collapsed a.main:active, header.collapsed a.main:hover, 
  header.collapsed a.main.active {
    padding-bottom: 0;
  }

  .swiper-container.gallery .swiper-slide img.responsive {
    width: auto;
    height: 480px;
    position: absolute;
    left: 50%;
    margin-left: -50%;
  }
  .swiper-container.gallery .swiper-slide {
    overflow: hidden;
  }
  .swiper-container.gallery {
    height: 480px;
  }
  .swiper-container.thumbs .swiper-slide img {
    width: auto;
    height: 50px;
  }
  .altura-slider{

  }
  .practicas .card p{
    margin-left: 0px;
    max-width: 100%;
    text-align: center;
  }
  .img-hierro-ductil-gris{
  display: block !important;
  width: 50%;
    margin: auto;
        margin-top: -380px;
}
#btn-mas-informacion-hierro-ductil-home, #btn-mas-informacion-hierro-gris-home{
 /* margin-top: 270px;*/
 margin-top: 192px;
}
  .margin-hierro-ductil-gris-left-home img, .margin-hierro-ductil-gris-right-home img{
    display: none;
  }

.margin-hierro-ductil-gris-left-home{
 background-color:  #17242a;
 padding-bottom: 323px;/* 100px;*/
}

.margin-hierro-ductil-gris-right-home{
 background-color:#17242a; /* #17242a;*/
padding-bottom: 323px;/*100px;*/
}

.margin-hierro-ductil-gris-right-home{
  padding-left: 0px !important;
}

.simpleParallax{
  margin-top: 100px;
}
.estilo-especial-politica-calidad-home p{
  text-align: center !important;
}
.estilo-especial-politica-calidad-home{
  padding-left: 15px !important;
  padding-right: 15px;
  text-align: center !important;
}
.politica-calidad-formulario-hr{
  text-align: center !important;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;
}
.politica-calidad-formulario-imagen img{
  width: 100%;
   
}

.btn-politica-calidad-formulario{
  margin-top: 110px !important;
}

.politica-calidad-formulario-imagen{
  margin-top: -270px !important;
    margin-bottom: 40px !important;
}
#btn-conoce-nuestra-calidad{
      margin-top: 100px;
}
.politica-calidad-formulario-imagen{
  height: 293px;
}
.politica-calidad-formulario-titulo{
  text-align: center;
  font-size: 30px;
}
#politica-calidad-formulario p{
  text-align: center !important;
}
/* footer */
.direccion-footer{
  padding-left: 0px !important;
}
}
@media (max-width: 480px) {}

@media (max-width: 605px){
  .texto-video{
    display: none;
  }
}


.margin-hierro-ductil-gris-left-home{
  padding-right: 40px;
}


.margin-hierro-ductil-gris-right-home{
  padding-left: 40px;
}
.margin-politica-calidad-contacto-home-left{
padding-right: 20px;
}

.margin-politica-calidad-contacto-home-right{
/*padding-left: 20px;*/
}


.display-ib{
  display: inline-block !important;
}
/* seccion calidad equipo */
.titulos-seccion-calidad-equipo{
   font-size: 30px;
  font-family: "Roboto Slab";
  letter-spacing:1.5px;
  line-height: 0.87;
  color: #ffffff;
  font-weight: 300;
  padding-bottom: 30px;
}

.contenidos-seccion-calidad-equipo{
   font-size: 15px;
  font-family: "Roboto";
  font-weight: 300;
  color: #8f8f8f;
  letter-spacing:0.75px;
  line-height: 1.74;
  margin: 0 !important;
  padding: 0 !important;
}
.calidad-equipo-filas-izquierda{

  margin-left: 280px;/* 160px;*/
  margin-top: 3%;
}

.calidad-equipo-filas-derecha{
  margin-left: -160px;
  margin-top: 3%;
}
.bienvenida-capacidad-equipo p{
  font-size: 12px;
}

.imagenes-rows-calidad-equipo img{
  width: 100%;
  /*height: 300px;*/

}
.capacidad-equipo-article p{
  width: 536px;
}
.capacidad-equipo-article{
  /*margin-top: 56px;*/
  margin-bottom: -80px;
height: 300px;
}
/* nosotros */
#bienvenida-nosotros{
/*margin-top: -460px;*/
}

.heading-bienvenida-nosotros{
  color: #ffffff;
  font-size: 35px;
  font-family: "Roboto Slab";
  font-weight: 300;
  letter-spacing:1.75px;
  line-height: 1.5;
      text-align: center;
}
#bienvenida-nosotros p{
  padding: 0;
  max-width: 550px;
  margin:0;
  color: #8f8f8f;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 300;
  letter-spacing:0.75px;
  line-height: 1.74;
  padding-top: 5px;

}

/* hierro Dúctil */

.grados-hierro-ductil{
  font-size: 18px !important;
  font-family: "Roboto Slab" !important;
  color: #dd9928 !important;
  letter-spacing:0.9px !important;
  line-height: 1.45 !important;
  padding-top: 15px !important;
}
.contenido-grados-hierro-ductil{
  padding-left: 50px !important;
  font-size: 15px !important;
  font-family: "Roboto" !important;
  font-weight: 300 !important;
  color: #8f8f8f !important;
  letter-spacing:0.75px !important;
  line-height: 1.74 !important;
}
.blanco-bold-contenido-grados-hierro-ductil{
  color: #ffffff !important;
  font-weight: 700 !important;
}
/* calidad-equipo */

.imagenes-rows-calidad-equipo{
  position: absolute;
}


/* Formularios*/

input[type=text]{
  height: 50px;
}

textarea{
  height: 150px;
}
#button-submit{
  width: 250px;
  height: 50px;
  text-align:center;
      top: -57px;
}
#recaptcha{
  margin-left: 300px;
}

.swiper-button-prev, .swiper-button-next{
  width: 69px;
}